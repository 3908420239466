import { SbBlokData, StoryblokComponent } from "@storyblok/react";
import { FC } from "react";

interface DynamicComponentRepeaterProps {
	content: SbBlokData[];
}
export const DynamicComponentRepeater: FC<DynamicComponentRepeaterProps> = ({ content }) => {
	return (
		<>
			{content
				? (content as SbBlokData[]).map((nestedBlok) => (
						<StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
				  ))
				: null}
		</>
	);
};
