import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { FC } from "react";
import { Section } from "ui";
import { StoryBaseProps } from "../types/StoryBaseProps";
import { DynamicComponentRepeater } from "./DynamicComponentRepeater";

interface StorySectionProps
	extends StoryBaseProps<{
		full_width?: boolean;
		vertical_alignment?: string;
		horizontal_alignment?: string;
		full_height?: boolean;
		content_full_width?: boolean;
		max_width?: string;
		content?: SbBlokData[];
	}> {}

export const StorySection: FC<StorySectionProps> = ({ blok }) => {
	const {
		full_width,
		content,
		vertical_alignment,
		horizontal_alignment,
		full_height,
		content_full_width,
		max_width,
		_uid,
	} = blok;
	return (
		<>
			<Section
				{...storyblokEditable(blok)}
				maxWidth={max_width}
				fullHeight={full_height}
				fullWidth={full_width}
				contentFullWidth={content_full_width}
				verticalAlignment={vertical_alignment}
				horizontalAlignment={horizontal_alignment}
				key={_uid}>
				<DynamicComponentRepeater content={content} />
			</Section>
		</>
	);
};
